
//libraries and component libraries
import React from 'react';
import { Menu, Segment, Sidebar, Icon, Accordion, } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import I18n from '../../../i18n';

//components
import Faq from '../../routes/faq';
import TeachableReducedDiagnosisConversionFunnel from '../../routes/diagnosis/reduced/conversion/funnel';
import UserQuestions from '../user/questions';
import UserList from '../user/list';
import ProList from '../pro/list';
import Notifications from '../notifications';
import Categories from '../category';
import ProgramList from '../sequence/components/programs'
import Asanas from '../sequence/components/asanas';
import AsanaEdit from '../sequence/components/asanas/edit';
import Payments from '../payments';
import SequenceList from '../sequence/components/list';
import SequenceCategory from '../sequence/components/category';
import SequencePreview from '../sequence/components/preview';
import SequenceEdit from '../sequence/edit';
import UserInfo from '../user/info';
import StudioList from '../studio';
import StudioEdit from '../studio/edit';
import ProgramEdit from '../sequence/components/programs/edit';
import TeachableStats from '../stats/teachable';
import AdvisorStats from '../stats/advisor';
import StellarList from '../stellar/list';

//redux actions
import { closeSession } from '../../../redux-store/auth';

//assets
import Logo from '../../assets/img/yogabot_icon.svg';
import './home.scss';
import Commissions from '../advisor/commissions';

class Home extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            animation: 'push',
            direction: 'left',
            dimmed: true,
            visible: true,
            activeItem: 'notifications',
            activeIndex: -1
        };

    }

    onToggle = () => {

        this.setState(prevState => {
            return { visible : !prevState.visible }
        });

    }

    setActiveItem = (e, { name, path }) => {

        this.setState({ activeItem: name }, () => {

            this.props.history.push(path);

        });

    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
      }

    render() {

        const { activeItem, activeIndex, visible, direction, animation } = this.state;

        return (
            <>
                <Menu inverted icon attached >
                    <Menu.Item>
                        <Icon name="bars" onClick={this.onToggle} size="large" />
                    </Menu.Item>
                    <Menu.Item>
                        <img src={Logo} alt="yogabot" />
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item name='sign-in' onClick={this.props.closeSession}>
                            <Icon name="sign out" size="large"/>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Sidebar.Pushable as={Segment} style={{margin: '0', border: '0', borderRadius: '0', height: 'auto', minHeight: '100%'}}>
                    <Sidebar
                        as={Menu}
                        animation={animation}
                        direction={direction}
                        visible={visible}
                        icon='labeled'
                        inverted
                        vertical={true}
                        width='thin'>
                            <Accordion inverted >
                                <Menu.Item active={ activeItem === 'notifications' } path="/home/notifications" onClick={ this.setActiveItem } name="notifications" as='a' style={{ textAlign: 'left' }}>
                                    {I18n.t('home.notificationUserList')}
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'users' } path="/home/user/list" onClick={ this.setActiveItem } name="users" as='a' style={{ textAlign: 'left' }}>
                                    {I18n.t('home.userList')}
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'pros' } path="/home/pro/list" onClick={ this.setActiveItem } name="pros" as='a' style={{ textAlign: 'left' }}>
                                    {I18n.t('home.proList')}
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'faq' } path="/home/faq" onClick={ this.setActiveItem } name="faq" as='a' style={{ textAlign: 'left' }}>
                                    <span>{I18n.t('home.faq')}</span>
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'questions' } path="/home/user/questions" onClick={ this.setActiveItem } name="questions" as='a' style={{ textAlign: 'left' }}>
                                    <span>{I18n.t('home.questionUsers')}</span>
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'categories' } path="/home/categories" onClick={ this.setActiveItem } name="categories" as='a' style={{ textAlign: 'left' }}>
                                    <span>{I18n.t('home.categories')}</span>
                                </Menu.Item>
                                <Menu.Item style={{ textAlign: 'left' }}>
                                    <Accordion.Title
                                        active={activeIndex === 0}
                                        content={I18n.t('home.stats')}
                                        index={0}
                                        onClick={this.handleClick}
                                    />
                                    <Accordion.Content active={activeIndex === 0}>
                                        <Menu.Item active={ activeItem === 'teachableStats' } path="/home/stats/teachable" onClick={ this.setActiveItem } name="teachableStats" as='a'  style={{ textAlign: 'left' }}>
                                            <span>Teachable</span>
                                        </Menu.Item>
                                        <Menu.Item active={ activeItem === 'advisorStats' } path="/home/stats/advisor" onClick={ this.setActiveItem } name="advisorStats" as='a' style={{ textAlign: 'left' }}>
                                            <span>Advisor</span>
                                        </Menu.Item>
                                    </Accordion.Content>
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'payments' } path="/home/payments" onClick={ this.setActiveItem } name="payments" as='a' style={{ textAlign: 'left' }}>
                                    <span>{I18n.t('home.payments')}</span>
                                </Menu.Item>
                                <Menu.Item active={ activeItem === 'studio' } path="/home/studio/list" onClick={ this.setActiveItem } name="studio" as='a' style={{ textAlign: 'left' }}>
                                    <span>{I18n.t('home.studio')}</span>
                                </Menu.Item>
                                <Menu.Item style={{ textAlign: 'left' }}>
                                    <Accordion.Title
                                        active={activeIndex === 1}
                                        content='Secuencias'
                                        index={1}
                                        onClick={this.handleClick}
                                    />
                                    <Accordion.Content active={activeIndex === 1}>
                                        <Menu.Item active={ activeItem === 'sequence' } path="/home/sequence" onClick={ this.setActiveItem } name="sequence" as='a'  style={{ textAlign: 'left' }}>
                                            <span>Listado</span>
                                        </Menu.Item>
                                        <Menu.Item active={ activeItem === 'sequenceCategories' } path="/home/sequence/category" onClick={ this.setActiveItem } name="sequenceCategories" as='a' style={{ textAlign: 'left' }}>
                                            <span>Categorias</span>
                                        </Menu.Item>
                                        <Menu.Item active={ activeItem === 'sequencePreview' } path="/home/sequence/preview" onClick={ this.setActiveItem } name="sequencePreview" as='a' style={{ textAlign: 'left' }}>
                                            <span>Previsualizar</span>
                                        </Menu.Item>
                                        <Menu.Item active={ activeItem === 'sequencePrograms' } path="/home/sequence/programs" onClick={ this.setActiveItem } name="sequencePrograms" as='a' style={{ textAlign: 'left' }}>
                                            <span>Programas</span>
                                        </Menu.Item>
                                        <Menu.Item active={ activeItem === 'sequenceAsanas' } path="/home/sequence/asanas" onClick={ this.setActiveItem } name="sequenceAsanas" as='a' style={{ textAlign: 'left' }}>
                                            <span>Asanas</span>
                                        </Menu.Item>
                                    </Accordion.Content>
                                </Menu.Item>
                                <Menu.Item style={{ textAlign: 'left' }}>
                                    <Accordion.Title
                                        active={activeIndex === 2}
                                        content='Stellar'
                                        index={2}
                                        onClick={this.handleClick}
                                    />
                                    <Accordion.Content active={activeIndex === 2}>
                                        <Menu.Item active={ activeItem === 'stellar' } path="/home/stellar/list" onClick={ this.setActiveItem } name="stellar" as='a'  style={{ textAlign: 'left' }}>
                                            <span>Listado</span>
                                        </Menu.Item>
                                    </Accordion.Content>
                                </Menu.Item>
                                <Menu.Item style={{ textAlign: 'left' }}>
                                    <Accordion.Title
                                        active={activeIndex === 3}
                                        content='Advisor'
                                        index={3}
                                        onClick={this.handleClick}
                                    />
                                    <Accordion.Content active={activeIndex === 3}>
                                        <Menu.Item active={ activeItem === 'stellar' } path="/home/advisor/commissions" onClick={ this.setActiveItem } name="commissions" as='a'  style={{ textAlign: 'left' }}>
                                            <span>Comisiones</span>
                                        </Menu.Item>
                                    </Accordion.Content>
                                </Menu.Item>
                            </Accordion>
                    </Sidebar>
                    <Sidebar.Pusher>
                        <Segment className={'segmentPushable ' + (visible ? 'isVisible' : '')} >
                            <Route path="/home/user/list" exact component={ UserList } />
                            <Route path="/home/pro/list" exact component={ ProList } />
                            <Route path="/home/notifications" exact component={ Notifications } />
                            <Route path="/home/diagnosis/reduced/conversion/funnel" exact component={ TeachableReducedDiagnosisConversionFunnel } />
                            <Route path="/home/faq" exact component={ Faq } />
                            <Route path="/home/user/questions" exact component={ UserQuestions } />
                            <Route path="/home/user/info/:userId" exact component={ UserInfo } />
                            <Route path="/home/stats/teachable" exact component={ TeachableStats } />
                            <Route path="/home/stats/advisor" exact component={ AdvisorStats } />
                            <Route path="/home/categories" exact component={ Categories } />
                            <Route path="/home/sequence" exact component={ SequenceList } />
                            <Route path="/home/sequence/category" exact component={ SequenceCategory } />
                            <Route path="/home/sequence/preview" exact component={ SequencePreview } />
                            <Route path="/home/sequence/programs" exact component={ ProgramList } />
                            <Route path="/home/sequence/asanas" exact component={ Asanas } />
                            <Route path="/home/sequence/:sequenceId" exact component={ SequenceEdit } />
                            <Route path="/home/sequence/program/edit/:programId?" exact component={ ProgramEdit } />
                            <Route path="/home/asanas/edit/:asanaId" exact component={ AsanaEdit } />
                            <Route path="/home/payments" exact component={ Payments } />
                            <Route path="/home/studio/list" exact component={ StudioList } />
                            <Route path="/home/studio/edit/:asanaId?" exact component={ StudioEdit } />
                            <Route path="/home/stellar/list" exact component={ StellarList } />
                            <Route path="/home/advisor/commissions" exact component={ Commissions } />
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </>
        );

    }

}

export default connect(null, { closeSession })(Home);